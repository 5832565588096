import theme from '@/style';
import {View} from 'react-native';
import HeaderTab from './components/header-tab/header-tab';
import React from 'react';
import FilterIcon from '@/components/svgs/basic/filer';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';

import Text from '@/components/basic/text';
import {DownIcon} from '../casino/svg.variable';

const ResultHeder = ({
  currentType,
  onTypeChange,
  showFilter,
  onFilter,
}: {
  currentType: string;
  onTypeChange?: (v: string) => void;
  showFilter?: boolean;
  onFilter?: () => void;
}) => {
  return (
    <View style={[theme.background.white]}>
      <View
        style={[
          theme.flex.row,
          theme.flex.centerByCol,
          theme.flex.between,
          theme.padding.l,
          // eslint-disable-next-line react-native/no-inline-styles
          {
            borderBottomColor: '#D8E0EA',
            borderBottomWidth: 1,
          },
        ]}>
        <HeaderTab
          onChange={onTypeChange}
          selectedKey={currentType}
          options={[
            {label: 'Lottery', value: 'Lottery'},
            {label: 'Casino', value: 'Casino'},
          ]}
        />
        {showFilter && (
          <NativeTouchableOpacity
            onPress={onFilter}
            style={[
              theme.flex.row,
              theme.flex.centerByCol,
              theme.padding.lrs,
              theme.padding.tbxxs,
              theme.borderRadius.xs,
              // eslint-disable-next-line react-native/no-inline-styles
              {
                gap: theme.paddingSize.xxs,
                borderWidth: 1,
                borderColor: '#D8E0EA',
              },
            ]}>
            <FilterIcon />
            <Text size="medium">Filter</Text>
            <View>
              <DownIcon />
            </View>
          </NativeTouchableOpacity>
        )}
      </View>
    </View>
  );
};

export default ResultHeder;
