import {http, racingHttp, indusWinHttp, racingResultHttp} from '@/utils';
import {
  ColorOrder,
  DiceOrderData,
  GameSource,
  IGameBaseParams,
  IGameOrder,
  IGameRangeParams,
  IGameTopItem,
  KeralaTicket,
  KeralaTicketParams,
  KeralaTypeResultItem,
  MatkaOrder,
  MatkaParams,
  MatkaResultItem,
  MatkaTypeItem,
  MixLottoListItem,
  RaceCarGroupItem,
  RaceCarHistoryParams,
  RaceCarOrder,
  RaceCarResultResp,
  RaceChips,
} from './result.type';

export function getIGameUserRanking(gameType: number) {
  return indusWinHttp.post<IGameRangeParams, IGameTopItem[]>(
    'iGaming/igaming/getTodayGameUserBonusRanking',
    {
      gameType,
      start: 0,
      end: 19,
    },
  );
}

export function getIGameLastOrder(gameType: number) {
  return indusWinHttp.post<IGameBaseParams, IGameOrder>(
    'iGaming/igaming/getLastOrderInfo',
    {
      gameType,
    },
  );
}

export function getKeralaTicketList(params: KeralaTicketParams) {
  return http.post<KeralaTicketParams, KeralaTicket[]>(
    'app/lottery/type/list',
    {
      type: 0,
      ...params,
    },
  );
}

export function getMatkaResultList(pageNo: number, searchId?: number) {
  return http.post<MatkaParams, MatkaResultItem[]>('app/matka/result/list', {
    pageNo,
    isEnd: 0,
    matkaId: searchId || 0,
  });
}

export function getRacecarGroupList() {
  return racingResultHttp.get<null, RaceCarGroupItem[]>(
    '/api/racing/v1/group/list',
    {
      params: {tag: 'sn'},
    },
  );
}

export function getRacecarHistory(params: RaceCarHistoryParams) {
  return racingResultHttp.post<RaceCarHistoryParams, RaceCarResultResp>(
    'api/racing/v1/lottery/history',
    params,
  );
}

export function getRacecarChip() {
  return racingResultHttp.post<null, RaceChips>('api/racing/v1/player/chips');
}

export function getRacecarLastOrder(groupName: string, player = '') {
  return racingResultHttp.post<{groupName: string}, RaceCarOrder>(
    'api/racing/v1/lottery/myrecords',
    {
      groupName,
      pageNo: 1,
      size: 1,
    },
    {
      params: {player},
    },
  );
}

export function getMixLottoList() {
  return racingHttp.post<null, MixLottoListItem[]>(
    '/api/loi/v1/lottery/history/latest',
  );
}

export function getQuickMixLottoList() {
  return racingHttp.post<null, MixLottoListItem[]>(
    'api/lqi/v1/lo/history/latest',
  );
}

export function getColorOrder() {
  return http.post<null, ColorOrder>('app/redGreen/order/lastTimeOrder');
}

export function getLastDiceOrder(configId: number) {
  return http.get<{configId: number}, DiceOrderData | Record<string, never>>(
    'app/diceThree/lastTimeOrder',
    {
      params: {configId},
    },
  );
}

export function getMatkaLastOrder() {
  return http.post<null, MatkaOrder>('app/matka/result/last/order');
}

export function getKeralaTicketTypeList() {
  return http.post<null, KeralaTypeResultItem[]>('app/lottery/type/typeList');
}

export function getMatkaTypeList() {
  return http.post<null, MatkaTypeItem[]>('app/matka/result/type/list');
}

export function getGameSource(gameId: number) {
  return indusWinHttp.get<{id: number}, GameSource>(
    'iGaming/common/getGameStart',
    {
      params: {id: gameId},
    },
  );
}
