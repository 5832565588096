import {http} from '@/utils';

export interface PagerResult<T> {
  totalPages: number;
  totalSize: number;
  content: T[];
}

export interface SattaConfig {
  label: string;
  value: string;
  digitTitle: string;
  describe: string;
  key?: number;
  row: number;
}

export interface SattaConfigResponse {
  Ank: string;
  Jodi: string;
  SP: string;
  DP: string;
  TP: string;
  HalfSangam: string;
  FullSangam: string;
}

export interface SattaConfigRes {
  doublePanna: number;
  fullSangam: number;
  halfSangam: number;
  jodiDigital: number;
  lotteryName: string;
  matkaId: number;
  singleDigital: number;
  singlePanna: number;
  triplePanna: number;
}

export interface SattaInfoResponse {
  allNum: number[];
  backImgWeb: string;
  closeDraw: string;
  closeResultNum: string;
  // 是否开启Full Sangam 0 开启 1 不开启
  fullStatus: number;
  // 是否开启Half Sangam 0 开启 1 不开启
  halfStatus: number;
  id: number;
  isClose: number;
  lotteryName: string;
  matkaConfigRes: SattaConfigRes;
  minPrice: number;
  openDraw: string;
  openResultNum: string;
  wonAmount: number;
}

export interface IMatkaResultVo {
  closeAnk: string;
  closeDp: string;
  closeNum: string;
  closeSp: string;
  closeTime: string; // 24小时制
  closeTp: string;
  createTime: number;
  fullSangam: string;
  hsaNum: string;
  hsbNum: string;
  issueNo: string;
  jodi: string;
  lotteryName: string;
  openAnk: string;
  openDp: string;
  openNum: string;
  openSp: string;
  openTime: string; // 24小时制
  openTp: string;
}

export interface SattaOrderDigitItem {
  digits: string;
  gameType: number;
  points: number;
  digitsWonAmount?: number;
  isWin?: number;
}

export interface SattaOrderItem {
  createTime: number;
  drawDate: string;
  gameName: string;
  orderGroup: string;
  wonAmount: number;
  status: number;
  openStatus: number;
  totalAmount: number;
  shareAward: number;
  matkaResultVo: IMatkaResultVo;
  userDigits: SattaOrderDigitItem[];
  gameIconUrl?: string;
}

export interface SattaOrderCreateDataContent {
  gameType: number; // 游戏类型  * 1、AnkOpen 2、AnkClose 3、jodi 4、SPOpen 5、SPColse 6、DPOpen 7、DPClose 8、TPOpen 9、TPClose 10、Half SangamOpen 11、Half SangamClose 12、Full Sangam
  digits: number | string; // 购买号码
  amount: number; // 购买金额
  type: string;
}

export interface MatkaOrderCreateData {
  matkaId: string; // 彩票id
  matkaBaseReqList: SattaOrderCreateDataContent[];
}

export const getSattaConfig = (matkaId: string) =>
  http.post<null, SattaConfigResponse>('app/matka/lottery/config', {matkaId});

/**
 * 彩票详情页
 * @param matkaId
 * @returns
 */
export const getSattaInfo = (matkaId: string) =>
  http.post<null, SattaInfoResponse>('app/matka/lottery/info', {matkaId});

export interface WinnerResponse {
  bonus: number;
  userName: string;
  userAvatar: string;
  userPhone: string;
}

export interface MatkaParams {
  matkaId: number;
  isEnd: number;
  pageNo: number;
  pageSize?: number;
}

export function buyMatka(data: MatkaOrderCreateData) {
  return http.post<MatkaOrderCreateData, string>(
    'app/matka/order/create',
    data,
  );
}

export const getWinners = () =>
  http.post<null, WinnerResponse[]>('app/matka/lottery/marquee', {group: 1});

export function getMatkaResultList(
  pageNo: number,
  pageSize: number,
  searchId?: number,
) {
  return http.post<
    MatkaParams,
    {
      content: IMatkaResultVo[];
      totalPages: number;
      totalSize: number;
    }
  >('app/matka/result/listV2', {
    pageNo,
    pageSize,
    isEnd: 1,
    matkaId: searchId || 0,
  });
}

export function getOrderListSatta(
  pageNo: number = 1,
  pageSize: number = 10,
  orderStatus: number,
) {
  return http.post<null, PagerResult<SattaOrderItem>>(
    'app/matka/order/listV2',
    {
      pageNo,
      orderStatus,
      pageSize,
    },
  );
}

export const calcResultNum = (resultNum?: string | null) => {
  if (!resultNum || !resultNum.length) {
    return '*';
  }
  const sum = resultNum.split('').reduce((s, x) => (s + +x) % 10, 0);
  return sum + '';
};
