import config from '@/utils/env.config';
import React, {useState} from 'react';
import theme from '@style';
import DetailNavTitle from '@businessComponents/detail-nav-title';
import {View} from 'react-native';
import {BasicObject} from '@/types';
import {getDigitType, goTo} from '@/utils';
import globalStore from '@/services/global.state';
import {useWebView} from '../hooks/webview.hooks';
import {packageId} from '@/config';
import ResultHeder from './result-header';
import Lottery from './lottery';
import Casino from './casino/casino';
import {getKeralaTicketTypeList, getMatkaTypeList} from './result-service';
import {KeralaTypeResultItem, MatkaTypeItem} from './result.type';
import Drawer, {DrawerRef} from '@/components/basic/drawer/drawer';
import FilterPanel from './components/filter-panel';

const Result = () => {
  const [title, setTitle] = useState<string>('Result');
  const [currentType, setCurrentType] = React.useState('Lottery');
  const [index, setIndex] = React.useState(0);
  const [back, setBack] = useState<number>(
    packageId === 2 && globalStore.channel?.indexOf('B') !== 0 ? 0 : 1,
  );
  const drawerRef = React.useRef<DrawerRef>(null);
  const [keralaType, setKeralaType] = React.useState<KeralaTypeResultItem[]>();
  const [matkaType, setMatkaType] = React.useState<MatkaTypeItem[]>();
  const [digitTypes, setDigitTypes] = React.useState<
    {label: string; value: number}[]
  >([]);
  const [selectType, setSelectType] = React.useState('ALL');
  // const [setPageLoading] = React.useState(true);
  const handleMessage = (data: string | BasicObject) => {
    if (data.startsWith?.('title:')) {
      // 表示更改标题
      setTitle(data.substring('title:'.length));
      return;
    }
    if (data === 'pageLoading:show') {
      // setPageLoading(true);
      return;
    }
    if (data === 'pageLoading:hide') {
      // setPageLoading(false);
      return;
    }
    if (data === 'back:show') {
      setBack(1);
      return;
    }
    if (data === 'back:hide') {
      setBack(0);
      return;
    }
    if (data.startsWith?.('scratch:')) {
      goTo('Scratch', {
        path: data.substring('scratch:'.length),
      });
    }
    if (data.startsWith?.('mix-lottery:')) {
      const path = data.substring('mix-lottery:'.length);
      const params = JSON.parse(path);
      goTo('MixLotteryDetail', params);
    }
  };
  const urlPrefix = `${config.reactH5Url}/result`;

  const {goBack} = useWebView({
    urlPrefix,
    onMessage: handleMessage,
  });

  const showFilter = React.useMemo(() => {
    if (
      currentType === 'Lottery' &&
      [1, 2, 6].includes(index) &&
      packageId !== 5 &&
      packageId !== 6
    ) {
      return true;
    }
  }, [currentType, index]);

  React.useEffect(() => {
    if (currentType === 'Lottery' && packageId !== 5 && packageId !== 6) {
      if (index === 1) {
        getKeralaType();
      }
      if (index === 2) {
        getDigitTypes();
      }
      if (index === 6) {
        getMatkaType();
      }
    }
  }, [currentType, index]);

  const getKeralaType = async () => {
    try {
      const res = await getKeralaTicketTypeList();
      setKeralaType(res || []);
    } finally {
    }
  };

  const getDigitTypes = async () => {
    const res = await getDigitType();
    setDigitTypes(res || []);
  };

  const getMatkaType = async () => {
    try {
      const res = await getMatkaTypeList();
      setMatkaType(res || []);
    } finally {
    }
  };

  const keralaTypes = React.useMemo(() => {
    if (keralaType && keralaType.length > 0) {
      const list = keralaType[0].lotteryTypeList;
      list.unshift('ALL');
      return list;
    }
    return [];
  }, [keralaType]);

  const matkaTypes = React.useMemo(() => {
    if (matkaType && matkaType.length > 0) {
      const list = matkaType.map(item => item.lotteryName);
      list.unshift('ALL');
      return list;
    }
    return [];
  }, [matkaType]);

  const targetDigitTypes = React.useMemo(() => {
    const list = digitTypes.map(item => item.label);
    list.unshift('ALL');
    return list;
  }, [digitTypes]);

  return (
    <View style={[theme.fill.fill, theme.flex.col]}>
      {title && (
        <DetailNavTitle
          title={title}
          hideServer={!!back}
          hideAmount={!!config.reactH5Url}
          serverRight
          onBack={back === 1 ? () => goBack() : undefined}
        />
      )}
      <ResultHeder
        onFilter={() => {
          drawerRef.current?.open();
        }}
        showFilter={showFilter}
        currentType={currentType}
        onTypeChange={setCurrentType}
      />
      {currentType === 'Lottery' ? (
        <Lottery
          digitTypes={digitTypes}
          currentIndex={index}
          onChange={setIndex}
          matkaType={matkaType}
          selectType={selectType}
        />
      ) : (
        <Casino />
      )}
      <Drawer ref={drawerRef} mode="top" contentBackgroundColor="#0000">
        <FilterPanel
          list={
            index === 1
              ? keralaTypes
              : index === 2
              ? targetDigitTypes
              : index === 6
              ? matkaTypes
              : []
          }
          game={
            index === 1
              ? 'Kerala'
              : index === 2
              ? '3 Digit'
              : index === 5
              ? 'Satta'
              : ''
          }
          onClose={() => drawerRef.current?.close()}
          selectKey={selectType}
          onSelect={v => {
            setSelectType(v);
            drawerRef.current?.close();
          }}
        />
      </Drawer>
    </View>
  );
};

export default Result;
